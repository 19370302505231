import React, { useState } from "react";

interface switchProps {
  className?: string;
  setToggle?:any;
  toggle?:boolean
}
export const SwitchBox = (props: switchProps) => {
  const { className, setToggle, toggle } = props;

  const toggleClass = " transform translate-x-5 ml-[3px]";
  return (
    <>
      <div
        className={`flex space-x-2  justify-end items-end pr-6 ${className}`}
      >
        <h4 className="items-center  flex ">Print on Verify</h4>
        <div
          className={`${
            toggle ? "bg-btnColor" : "bg-gray-500"
          } md:w-14 md:h-7 w-12 h-6 flex items-center rounded-full p-1 cursor-pointer`}
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <div
            className={
              "bg-[#fff] md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
              (!toggle ? null : toggleClass)
            }
          ></div>
        </div>
      </div>
    </>
  );
};
