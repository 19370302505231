import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../services/slices/loginSlice";
import { LoaderSpin } from "../components/Loader";
import SelectInput from "../components/SelectInput";

interface Props {
  setSaveToken: any;
  saveToken: string | null;
}
const Login = ({ saveToken, setSaveToken }: Props) => {
  const [username, setUsername] = useState("");
  const [usertype, setUsertype] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [usertypeError, setUsertypeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [
    loginUser,
    {
      isLoading: isLoginLoading,
      error: loginError,
      isError: isLoginError,
      isSuccess: isLoginSuccess,
      data: loginData,
    },
  ] = useLoginUserMutation();
  const navigate = useNavigate();
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  const userTypeData = [
    { name: "Fez Admin", value: "fez_admin" },
    { name: "TPL Admin", value: "tpl_admin" },
    { name: "Fez Rider", value: "fez_rider" },
    { name: "TPL Rider", value: "tpl_rider" },
  ];

  const onLoginUser = async () => {
    try {
      setUsernameError(username === "" ? "Please enter your username." : "");
      setPasswordError(password === "" ? "Please enter your password." : "");
      setUsertypeError(password === "" ? "Please enter your usertype." : "");
      if (username !== "" && password !== "" && usertype !== "") {
        const res = await loginUser({
          username,
          password,
          userType: usertype,
        }).unwrap();
        if (res) {
          setSaveToken(res.data.token);
          setUsername("");
          setPassword("");
          setUsertype("");
        }
      }
    } catch (e) {
      console.log({});
    }
  };
  useEffect(() => {
    if (isLoginSuccess && loginData) {
      localStorage.setItem("token", loginData.data.token);
      localStorage.setItem("usertype", usertype);
      localStorage.setItem("userId", loginData.data?.user?.cpid);
      localStorage.setItem("riderId", loginData.data?.user?.ridersID);
      localStorage.setItem("tplId", loginData.data?.user?.tpl_id);
      if (usertype.toLowerCase() === "fez_admin") {
        navigate("/");
      } else {
        navigate("/");
      }
    }
  }, [isLoginSuccess, loginData, usertype, navigate]);

  useEffect(() => {
    setError((loginError as any)?.data?.message);
    setTimeout(() => {
      setError([]);
    }, 5000);
  }, [isLoginError, loginError]);



  return (
    <div className="bg-logoBg pb-14 border space-y-10 lg:space-y-10 pt-14 mb-10 w-full h-screen  flex flex-col items-center">
      <div className="space-y-4 flex justify-center flex-col ">
        <img src="/images/fez_logo.png" alt="" className="mb-" />

        <h1 className="text-[24px] text-center font-semibold text-textColorGreen">
          Login
          {error && <p className="text-[12px] mt-5 text-[#e7797e]">{error}</p>}
        </h1>
      </div>

      <div className="w-full space-y-5 px-3 sm:px-10 md:px-20 lg:px-32">
        <div className="flex flex-col ">
          <label
            htmlFor="phone"
            className="text-[#1e221d] font-semibold text-[15px]"
          >
            User Type
          </label>
          <div className="border-b mt-4 bg-transparent border-textColorGreen">
            <SelectInput
              value={usertype}
              name={`usertype`}
              isRequired
              clearValue={true}
              inputClassName="!text-[#363e36]  px-[15px] !text-[13px]"
              placeholder={"Select User type"}
              className="!border-0 "
              handleChange={(name, value) => {
                setUsertype(value);
              }}
              dropdownOptions={userTypeData?.map((data: any, idx) => ({
                label: data?.name,
                value: data?.value,
              }))}
            />
          </div>
          {usertypeError !== "" && (
            <p className="text-[12px] mt-1.5 text-[#e7797e]">{usertypeError}</p>
          )}
        </div>
        <div className="flex flex-col ">
          <label
            htmlFor="phone"
            className="text-[#1e221d] font-semibold text-[15px]"
          >
           {(usertype.toLowerCase() === "fez_admin" || usertype.toLowerCase() === "tpl_admin") ? "User Name" : "Email/Phone number"}
          </label>
          <div className="border-b mt-4 bg-transparent border-textColorGreen">
            <input
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              name="phone"
              placeholder={(usertype.toLowerCase() === "fez_admin" || usertype.toLowerCase() === "tpl_admin") ? "Enter Username":" Enter Email/Phone number"}
              className="input outline-none bg-[transparent] focus:bg-transparent placeholder:text-[#363e36] focus:ring-0 -ml-1 focus:border-none focus:outline-none border-none font-inter ring-0  text-[#363e36]  px-[15px] text-[13px] block w-full p-2"
            />
          </div>
          {usernameError !== "" && (
            <p className="text-[12px] mt-1.5 text-[#e7797e]">{usernameError}</p>
          )}
        </div>

        <div className="flex flex-col ">
          <label
            htmlFor="password"
            className="text-[#1e221d] font-semibold text-[15px]"
          >
            Password
          </label>
          <div className="border-b flex bg-transparent justify-between mt-4 border-textColorGreen">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              className="outline-none bg-[transparent]  focus:bg-transparent placeholder:text-[#363e36] focus:ring-0 -ml-1 focus:border-none focus:outline-none border-none font-inter ring-0  text-[#363e36]  px-[15px] text-[13px] block w-full p-2"
            />
            <button
              className=" text-textColorGreen"
              onClick={togglePasswordVisibility}
            >
              {!isPasswordVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="flex justify-between">
            <p className="text-[12px] mt-1.5 text-[#e7797e]">
              {passwordError !== "" && passwordError}
            </p>

            {/* <p className="pt-5 cursor-pointer text-textColorGreen font-medium text-[13px]">
              Forgot Password?
            </p> */}
          </div>
        </div>
        <div className="pt-2 border">
          <button
            className="bg-btnColor w-full hover:bg-[#289D17] py-4 font-normal text-center rounded cursor-pointer text-[20px] text-white"
            onClick={onLoginUser}
            disabled={isLoginLoading}
          >
            {isLoginLoading ? <LoaderSpin color="#ffffff" /> : "Log in"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
