import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./slices/loginSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { orderApi } from "./slices/orderSlice";
import { customerApi } from "./slices/customerSlice";
import { selectedRowsReducer } from "./selectedRowReducer";
import { riderApi } from "./slices/riderSlice";
import { tplApi } from "./slices/tpl";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [riderApi.reducerPath]: riderApi.reducer,
    [tplApi.reducerPath]: tplApi.reducer,
    selectedRows: selectedRowsReducer,
  },

  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      orderApi.middleware,
      customerApi.middleware,
      riderApi.middleware,
      tplApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
