import { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSearchOrderMutation } from "../services/slices/orderSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ListResponse } from "../services/slices/customerSlice";
import axios from "axios";
import { LoaderSpin } from "../components/Loader";
import { loadOptions } from "../components/LoadOptions";

interface OptionType {
  value: string;
  label: string;
}

interface AdditionalType {
  page: number;
}

interface Props {
  onLogOut: any;
  setSearchData: any;
  currentPage: any;
  startDate: any;
  endDate: any;
  setStartDate: any;
  setEndDate: any;
  value: any;
  setValue: any;
  setSearchText: any;
  searchText: any;
  status: any;
  setStatus: any;
}

const SearchPage = ({
  onLogOut,
  setSearchData,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setSearchText,
  searchText,
  currentPage = 1,
  value,
  setValue,
  status,
  setStatus,
}: Props) => {
  const [
    searchOrder,
    { data: orderData, isLoading: isOrderLoading, isSuccess: isOrderSuccess },
  ] = useSearchOrderMutation();
  const navigate = useNavigate();
  const params = {
    searchText: searchText,
    client: value,
    startDate,
    endDate,
    page: currentPage,
    status: status,
  };
  const [error, setError] = useState("");

  const loadPageOptions = async (
    search: string,
    prevOptions: unknown,
    { page }: AdditionalType
  ): Promise<{
    options: OptionType[];
    hasMore: boolean;
    additional: {
      page: number;
    };
  }> => {
    const token = localStorage.getItem("token");
    const response = await axios.get<ListResponse>(
      `${process.env.REACT_APP_URL}clients?search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size: 10,
          paginate: true,
        },
      }
    );
    const data = response?.data?.data?.data.map(({ clientId, fullName }) => ({
      value: clientId,
      label: fullName,
    }));
    let filteredOptions: OptionType[];
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
    return {
      options: [{ label: "Select Options", value: "" }, ...filteredOptions],
      hasMore: data.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSearch = async () => {
    try {
      if (
        startDate !== "" &&
        endDate !== "" &&
        value === "" &&
        searchText === ""
      ) {
        setError("select a client or input a search text");
        setTimeout(() => {
          setError("");
        }, 5000);
      }
      if (
        (startDate !== "" && endDate !== "" && value !== "") ||
        searchText !== ""
      ) {
        // Perform the login logic
        navigate({
          pathname: "/order",
          search: `?${createSearchParams(params)}`,
        });
      }
    } catch (e) {
      console.log({});
    }
  };

  const onSelectChange = (data: any) => {
    setValue(data?.value);
  };
  const onChange = (data: any) => {
    setStatus(data?.value);
  };

  return (
    <div className=" h-[100%] bg-white py-8 px-7 md:px-10">
      <div className="flex items-center justify-between">
        <div>
          <div>
            <img
              src="/images/fez_logo.png"
              className="w-[100px] md:w-[150px] md:h-[100px] h-[70px]"
              alt=""
            />
          </div>
          <h1 className="ml-5 text-[17px] font-normal">
            BarCode Verification |
            <span className="text-textColorGreen"> Search Page</span>
          </h1>
        </div>
        <div className="flex items-center space-x-3 pr-10">
          <div onClick={()=>navigate("/scan-qr-code")}>
            <p className="text-textColorGreen underline cursor-pointer">Scan Page</p>
          </div>
          <div className="" onClick={onLogOut}>
            <button>Logout</button>
          </div>
        </div>
      </div>
      <div className="md:px-[30px] sm:px-[10px] px-[1px] mt-10">
        <div className="bg-white sm:px-10 px-5 rounded-[10px] drop-shadow-lg  shadow-lg  shadow-[#dfdbdb] min-h-[700px] pt-10  w-full flex flex-col">
          <h1 className="text-[24px] text-center text-textColorGreen">
            Search Orders
          </h1>
          <div className="w-full flex justify-center">
            <div className=" w-[350px] items-center space-x-1 bg-white flex">
              <img
                src="/images/info.png"
                className="w-[10px] h-[10px] "
                alt=""
              />
              <p className="text-center  text-textColorBlack text-[11px] ">
                Select start and end date and client name or search text!
              </p>
            </div>
          </div>
          {error && (
            <p className="text-[14px] mt-5 text-center text-[#e7797e]">
              {error}
            </p>
          )}
          <div className="space-y-5 gap-4 w-full grid  grid-cols-1 xl:grid-cols-3 mt-5">
            <div className="flex w-full sm:flex-row flex-col  h-fit sm:space-y-0 space-y-2 sm:space-x-5  mt-5">
              <p className="whitespace-nowrap items-center text-[17px] text-textColorBlack mt-2">
                Client's Name
              </p>
              <AsyncPaginate
                className="w-full"
                // @ts-ignore
                loadOptions={loadPageOptions}
                onChange={onSelectChange}
                additional={{
                  page: 1,
                }}
              />
            </div>
            <div className="flex w-full sm:flex-row flex-col sm:space-y-0 space-y-2 h-fit sm:space-x-5 ">
              <p className="whitespace-nowrap items-center text-[17px] text-textColorBlack">
                filter by status
              </p>
              <AsyncPaginate
                className="w-full -mt-5"
                // value={statusValue}
                loadOptions={loadOptions}
                onChange={onChange}
              />
            </div>
            <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:space-x-5 mt-10">
              <p className="whitespace-nowrap items-center text-[17px] text-textColorBlack mt-2">
                Search Text
              </p>
              <div className="flex flex-col w-full">
                <input
                  className={`focus:outline-none text-[14px] px-2 border  w-full py-3 placeholder:text-[14px] border-[#F1F1F1] font-productSans text-textBlack placeholder:text-[#848484] rounded-[8px] focus:border-[#F1F1F1]  focus:ring-0`}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="sender/recipient details"
                  name="search-text"
                  disabled={endDate === "" || startDate === ""}
                />
                <span className="text-[12px] mt-0.5">
                  sender/recipient name, sender/recipient phone,
                  sender/recipient email
                </span>
              </div>
            </div>
            <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:space-x-5 mt-10">
              <p className="whitespace-nowrap items-center text-[17px] text-textColorBlack mt-2">
                Order Creation Start Date
              </p>
              <input
                type="date"
                value={startDate}
                className={`focus:outline-none text-[14px] cursor-text  px-2 border  w-full py-3  placeholder:text-[14px] border-[#F1F1F1] font-productSans text-textBlack placeholder:text-[#848484] rounded-[8px] focus:border-[#F1F1F1]  focus:ring-0`}
                onChange={(e) => setStartDate(e.target.value)}
                name="date"
              />
            </div>
            <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-4 sm:space-x-5 mt-10">
              <p className="whitespace-nowrap items-center text-[17px] text-textColorBlack mt-2">
                Order Creation End Date
              </p>
              <input
                type="date"
                value={endDate}
                className={`focus:outline-none text-[14px] px-2 border  w-full py-3  placeholder:text-[14px] border-[#F1F1F1] font-productSans text-textBlack placeholder:text-[#848484] rounded-[8px] cursor-text focus:border-[#F1F1F1]  focus:ring-0`}
                onChange={(e) => setEndDate(e.target.value)}
                name="date"
              />
            </div>
          </div>
          <div className="mt-16 md:mb-0 mb-10 flex justify-center">
            <button
              className="bg-btnColor w-full hover:bg-[#289D17] py-4 font-normal text-center rounded cursor-pointer text-[20px] text-white"
              onClick={onSearch}
              disabled={isOrderLoading}
            >
              {isOrderLoading ? <LoaderSpin color="#ffffff" /> : "Search"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
