import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface searchProps {
  clientId: string | null;
  page: string | null;
  search: string | null;
  startCreatedDate: string | null;
  endCreatedDate: string | null;
  orderStatus: string | null;
}
interface verifyProps {
  id: number;
  rejectionId?: number;
  status: boolean;
  medias?: string[];
}
interface Props {
  orders: String[];
  rider: number;
  userId: string;
  action: string;
  EnrouteTo3PL?: string;
  tpl?: number;
}
export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    searchOrder: builder.mutation<any, searchProps>({
      query: ({ page, ...body }) => ({
        url: `orders/search?page=${page}&size=30`,
        method: "POST",
        body: body,
      }),
    }),
    acceptedVerifiedOrders: builder.mutation<any, searchProps>({
      query: ({ page, ...body }) => ({
        url: `orders/search?page=${page}&size=30`,
        method: "POST",
        body: body,
      }),
    }),
    getOrders: builder.query<void, void>({
      query: () => ({
        url: `orders`,
        method: "GET",
      }),
    }),
    getRejectionReasons: builder.query<any, void>({
      query: () => ({
        url: `orderRejectionReasons`,
        method: "GET",
      }),
    }),
    dispatchOrder: builder.mutation<any, Props>({
      query: (body) => ({
        url: `orders/dispatch`,
        method: "POST",
        body,
      }),
    }),
    verifyOrder: builder.mutation<void, verifyProps>({
      query: ({ id, ...patch }) => ({
        url: `orders/${id}/verify`,
        method: "POST",
        body: patch,
      }),
    }),
  }),
});

export const {
  useSearchOrderMutation,
  useDispatchOrderMutation,
  useAcceptedVerifiedOrdersMutation,
  useGetOrdersQuery,
  useGetRejectionReasonsQuery,
  useVerifyOrderMutation,
} = orderApi;
