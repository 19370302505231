import type { GroupBase, OptionsOrGroups } from "react-select";

export type OptionType = {
  value: string;
  label: string;
};

const options: OptionType[] = [
      {
        value:"Assigned To A Rider",
          label:"Assigned To A Rider"
      },
      {
        value:"Picked-Up",
          label:"Picked-Up"
      },
      {
        value:"Exported",
          label:"Exported"
      },
      {
        value:"Accepted At Inventory Facility",
          label:"Accepted At Inventory Facility"
      },
      {
        value:"Rejected At Inventory Facility",
          label:"Rejected At Inventory Facility"
      },
      {
        value:"Dispatched",
          label:"Dispatched"
      },
      {
        value:"Enroute To Last Mile Hub",
          label:"Enroute To Last Mile Hub"
      },
      {
        value:"Accepted At Last Mile Hub",
          label:"Accepted At Last Mile Hub"
      },
      {
        value:"Rejected At Last Mile Hub",
          label:"Rejected At Last Mile Hub"
      },
      {
        value:"Delivered",
          label:"Delivered"
      },

      {
        value:"In Return To Last Mile Hub",
          label:"In Return To Last Mile Hub"
      },
      {
        value:"Returned To Last Mile Hub",
          label:"Returned To Last Mile Hub"
      },
      {
        value:"In Return To First Mile Hub",
          label:"In Return To First Mile Hub"
      },
      {
        value:"Returned To First Mile Hub",
          label:"Returned To First Mile Hub"
      },
      {
        value:"In Return To Customer",
          label:"In Return To Customer"
      },
      {
        value:"Returned",
          label:"Returned"
      },
]

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

export const loadOptions = async (
  search: string,
  prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>
) => {
  await sleep(1000);

  let filteredOptions: OptionType[];
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore
  };
};
