import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { LoaderSpin } from "./Loader";

interface Props {
  webcamRef: any;
  capturedImages: [];
  capture: any;
  close: any;
  setHasInitialPicture: any;
  setAddingMorePictures: any;
  handleRemoveImages: any;
  onVerify: any;
  onClose: any;
  hasInitialPicture: boolean;
  isLoading: boolean;
  addingMorePictures: boolean;
}

export const WebcamCapture = ({
  onVerify,
  webcamRef,
  capture,
  close,
  capturedImages,
  hasInitialPicture,
  addingMorePictures,
  setAddingMorePictures,
  setHasInitialPicture,
  handleRemoveImages,
  onClose,
  isLoading,
}: Props) => {
const [selectedCamera, setSelectedCamera] = useState("user");
const [constraints, setConstraints] = useState<
  MediaTrackConstraints | undefined
>(undefined);
const [error, setError] = useState("");


useEffect(() => {
  const getVideoConstraints = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length > 0) {
        const facingMode =
          selectedCamera === "user" ? "user" : { exact: "environment" };
        const updatedConstraints: MediaTrackConstraints = {
          width: 480,
          height: 320,
          facingMode: facingMode,
        };
        setConstraints(updatedConstraints);
        setError("");
      } else {
        setError("No video input devices found.");
      }
    } catch (error) {
      setError("Error accessing media devices.");
    }
  };

  getVideoConstraints();
}, [selectedCamera]);

  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-blackAlpha   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative p-4 w-full flex items-center  max-w-[450px] h-[100vh] md:h-auto mx-auto">
        <div className="relative  min-w-[300px]  rounded-[20px]  shadow">
          {!hasInitialPicture && !addingMorePictures && (
            <div className="rounded-[20px] pb-2">
              <Webcam
                audio={false}
                height={420}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={constraints}
                className="rounded-[20px] pb-3 w-full"
              />
              <div className="flex space-x-4">
                <button
                  className="bg-textColorGreen py-2.5 text-white border  cursor-pointer px-3 text-center rounded-full w-[100%] "
                  onClick={capture}
                >
                  Capture photo
                </button>
                <button
                  className="text-textColorGreen py-2.5 bg-white border  cursor-pointer px-3 text-center rounded-full w-[100%] "
                  onClick={close}
                >
                  Cancel
                </button>
              </div>
              <select
                className="text-white py-2.5 bg-textColorGreen focus:ring-0  focus:border-white   cursor-pointer px-3 text-center rounded-full w-[100%] mt-10 "
                name="camera-type"
                onChange={(e) => setSelectedCamera(e.target.value)}
              >
                <option value="user">
                  Front Camera
                </option>
                <option value="environment">Back Camera</option>
              </select>
            </div>
          )}
          {addingMorePictures && (
            <div className="rounded-[20px] pb-2">
              <Webcam
                audio={false}
                height={420}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={constraints}
                className="rounded-[20px] pb-3 w-full"
              />
              <div className="flex space-x-4">
                <button
                  className="bg-textColorGreen py-2.5 text-white border  cursor-pointer px-3 text-center rounded-full w-[100%] "
                  onClick={() => {
                    setAddingMorePictures(false);
                    capture();
                  }}
                >
                  Capture photo
                </button>
                <button
                  className="text-textColorGreen py-2.5 bg-white border  cursor-pointer px-3 text-center rounded-full w-[100%] "
                  onClick={onVerify}
                >
                  Verify
                </button>
              </div>
              <select
                className="text-white py-2.5 bg-textColorGreen focus:ring-0  focus:border-white   cursor-pointer px-3 text-center rounded-full w-[100%] mt-10 "
                name="camera-type"
                onChange={(e) => setSelectedCamera(e.target.value)}
              >
                <option
                  value="user"
                >
                  Front Camera
                </option>
                <option value="environment">Back Camera</option>
              </select>
            </div>
          )}
          {hasInitialPicture && (
            <div className="bg-[white] relative rounded-[20px] py-3 px-3 ">
              <div className=" flex items-end justify-end cursor-pointer absolute -right-1.5 -top-4  md:-mr-3  -mr-2 md:-mb-3 z-10">
                <div
                  className="w-8 h-8 sm:h-14 sm:w-14 bg-[black] absolute -top-4 -left-12  flex items-center   justify-center rounded-full"
                  onClick={onClose}
                >
                  <img src="/images/star.svg" alt="" />
                </div>
              </div>
              <div className="grid gap-2 grid-cols-2 mt-2 mb-3">
                {capturedImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      height={150}
                      width={150}
                      alt={`Captured ${index + 1}`}
                      className="mb-3 rounded-[10px]"
                    />
                    <div
                      className="absolute -top-2 -right-2 text-white rounded-full p-2 cursor-pointer transition-colors"
                      onClick={() => handleRemoveImages(index)}
                    >
                      <img
                        src="/images/cancel.png"
                        className="w-5 h-5 "
                        alt="remove"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex space-x-4">
                <button
                  className="bg-textColorGreen py-2.5 w-full text-white border cursor-pointer px-3 text-center rounded-full"
                  onClick={() => {
                    setAddingMorePictures(true);
                    setHasInitialPicture(false);
                  }}
                >
                  Add More
                </button>
                <button
                  className="text-textColorGreen py-2.5 bg-white border border-textColorGreen cursor-pointer px-3 text-center rounded-full w-full "
                  onClick={onVerify}
                >
                  {isLoading ? <LoaderSpin color="#1e5c2e" /> : "Verify"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
