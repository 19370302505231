import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Order from "./pages/Order";
import SearchPage from "./pages/SearchPage";
import PrintPage from "./pages/PrintPage";
import { NotFound } from "./pages/NotFound";
import Scanner from "./pages/Scanner";
const user: any = localStorage.getItem("token") !== null;

function App() {
  return (
    <BrowserRouter>
      <MyComponent />
    </BrowserRouter>
  );
}

function MyComponent() {
  const [token, setToken] = useState<string | null>(null);
  const [searchData, setSearchData] = useState([]);
  const [saveToken, setSaveToken] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const todayDate = new Date().toJSON().slice(0, 10);
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);
  const userType = localStorage.getItem("usertype");
  const navigate = useNavigate();

  const onLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("usertype");
    navigate("/login");
  };
  const onClick = () => {
    setSearchText("");
    setValue("");
    setStatus("");
    navigate("/");
  };
  
  const params = {
    searchText: searchText,
    client: value,
    startDate,
    endDate,
    page: currentPage,
  };
  const onClickBackToOrder = () => {
    navigate({
      pathname: "/order",
      // search: `?${createSearchParams(params)}`,
    });
  };

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route
          path="/login"
          element={<Login setSaveToken={setSaveToken} saveToken={user} />}
        />
      </Route>
      <Route element={<ProtectRoute />}>
        {userType?.toLowerCase() === "fez_admin" ? (
          <Route path={"/scan-qr-code"} element={<Scanner />} />
        ) : (
          <Route path={"/"} element={<Scanner />} />
        )}

        {userType?.toLowerCase() === "fez_admin" && (
          <>
            <Route
              path="/"
              element={
                <SearchPage
                  startDate={startDate}
                  endDate={endDate}
                  value={value}
                  setValue={setValue}
                  searchText={searchText}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setSearchText={setSearchText}
                  setSearchData={setSearchData}
                  currentPage={currentPage}
                  onLogOut={onLogOut}
                  status={status}
                  setStatus={setStatus}
                />
              }
            />
            <Route
              path="/order"
              element={
                <Order
                  setSearchData={setSearchData}
                  searchData={searchData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  token={user}
                  onClickBack={onClick}
                  onLogOut={onLogOut}
                />
              }
            />
            <Route
              path="/print"
              element={
                <PrintPage
                  setSearchData={setSearchData}
                  searchData={searchData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  token={user}
                  onClickBack={onClickBackToOrder}
                  onLogOut={onLogOut}
                />
              }
            />
          </>
        )}

        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export const PublicRoute = () => {
  const user: any = localStorage.getItem("token") !== null;
  return !user ? <Outlet /> : <Navigate to="/" />;
};

export const ProtectRoute = () => {
  const user: any = localStorage.getItem("token") !== null;
  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default App;
