import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface UserInfo {
  status: boolean,
  message: string,
  data: {
      token: string,
      user:{
        cpid:string,
        ridersID:string,
        tpl_id:string,
      }
  },
}

interface LoginProps{
 username:string,
 password: string,
 userType:string
}

export const authApi = createApi({
  reducerPath:"authApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_URL,
 }),
  endpoints: (builder) => ({
   loginUser: builder.mutation<UserInfo, LoginProps>({
      query: (body) => ({
        url: `login`,
        method: 'POST',
        body
        
      }
      ),
    
  }),
})
})

export const { useLoginUserMutation } = authApi