import React from "react";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className=" h-screen items-center flex flex-col justify-center my-auto">
      <div className=" flex flex-col items-center justify-center">
        <p className="font-onestMedium text-3xl mb-5">404</p>
        <p className="font-onestMedium text-lg mb-5">PAGE NOT FOUND</p>
      </div>
      <button
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-btnColor hover:bg-[#289D17] rounded-lg"
        onClick={() => navigate("/")}
        disabled={false}
        name="index-page"
      >
        Home
      </button>
    </div>
  );
};
