/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    ChangeEvent,
  } from "react";
import { LoaderSpin } from "./Loader";
  
  interface DropdownOption {
    label: string | JSX.Element;
    value: any;
    icon?: JSX.Element;
  }
  
  interface SelectInputProps {
    label?: string;
    handleChange?: (name: string, value: any, label:any) => void;
    name?: string;
    value?: any;
    disabled?: boolean;
    placeholder?: string;
    inputPlaceholder?: string;
    dropdownOptions?: DropdownOption[];
    isRequired?: boolean;
    className?: string;
    id?: string;
    labelClassName?: string;
    dropdownClassName?: string;
    optionItemClassName?: string;
    optionItemContainerClassName?: string;
    activeOptionItemContainerClassName?: string;
    activeOptionItemClassName?: string;
    setDropdownHeight?: (
      height: { offsetHeight: number; clientHeight: number } | null
    ) => void;
    searchLoading?: boolean;
    clearValue?: boolean;
    handleInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    customDropdownContainer?: JSX.Element;
    inputClassName?: string;
    iconClassName?: string;
    floatingPlaceholderClass?: string;


  }
  
  const SelectInput: React.FC<SelectInputProps> = ({
    label,
    handleChange,
    name,
    value,
    disabled,
    placeholder,
    dropdownOptions,
    isRequired,
    className,
    id,
    clearValue,
    dropdownClassName,
    optionItemClassName,
    optionItemContainerClassName,
    activeOptionItemContainerClassName,
    setDropdownHeight,
    searchLoading,
    handleInputChange,
    customDropdownContainer,
    inputClassName,
  }) => {
    const [open, setOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<JSX.Element | null>(null);
    const [filter, setfilter] = useState<string>(value);
    const customDropdown = document.getElementById("custom-select");
  
    const selectedOption = dropdownOptions?.find((item) => {
      if (typeof item.label === "string") {
        return item?.value === value && item?.label?.includes(filter);
      } else if (typeof item.label === "object" && item.label.props) {
        const labelElement = item.label;
        const labelText = extractTextFromJSX(labelElement.props.children);
  
        if (typeof labelText === "string") {
          return item?.value === value;
        }
      }
      return false;
    });
  
    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const toggleRef = useRef<HTMLSpanElement>(null);
  
    function extractTextFromJSX(
      jsxElement: string | JSX.Element | JSX.Element[] | null | undefined
    ): string {
      if (typeof jsxElement === "string") {
        return jsxElement;
      } else if (Array.isArray(jsxElement)) {
        return jsxElement.map(extractTextFromJSX).join(" ");
      } else if (jsxElement?.props && jsxElement?.props?.children) {
        return extractTextFromJSX(jsxElement.props.children);
      } else {
        return "";
      }
    }
  
    const filteredOptions = isTyping
      ? dropdownOptions?.filter((item: any) => {
          const labelElement = item?.label;
          if (typeof labelElement === "object" && labelElement.props) {
            const labelText = extractTextFromJSX(labelElement);
            return (
              labelText?.toLowerCase().includes(filter?.toLowerCase()) ||
              item?.value?.toLowerCase().includes(filter?.toLowerCase())
            );
          }
    
          return (
            item?.label?.toLowerCase().includes(filter?.toLowerCase()) ||
            (typeof item?.value === "object"
              ? JSON.stringify(item?.value)
                  ?.toLowerCase()
                  .includes(filter?.toLowerCase())
              : item?.value?.toLowerCase().includes(filter?.toLowerCase()))
          );
        })
      : dropdownOptions;
  
    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
      setIsTyping(true);
      setOpen(true);
      handleChange?.(name || "", "", "");
      setSelectedIcon(null);
      setfilter(event.target.value);
    };
  
    const handleSelect = useCallback(
      (label: string, value: any, icon?: JSX.Element) => {
  
        handleChange?.(name || "", value, label);
        const selected = dropdownOptions?.find(
          (item) => item?.value === value && item?.label === label
        );
        if (selected) {
          const labelText = extractTextFromJSX(selected?.label);
          setfilter(labelText);
        }
  
        setIsTyping(false);
        setOpen(false);
      },
      [dropdownOptions, handleChange, name, setIsTyping, setOpen, "5", label]
    );
  
  
  
    useEffect(() => {
      if (open && setDropdownHeight) {
        setDropdownHeight({
          offsetHeight: customDropdown?.offsetHeight || 0,
          clientHeight: customDropdown?.offsetHeight || 0,
        });
      } else if (!open && setDropdownHeight) {
        setDropdownHeight(null);
      }
    }, [
      open,
      setDropdownHeight,
      customDropdown?.offsetHeight,
      customDropdown?.offsetHeight,
    ]);
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && event.target !== toggleRef.current) {
          if (!ref.current.contains(event.target as Node)) {
            setOpen(false);
          }
        }
  
        if (!event.target) setOpen(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, open]);
    useEffect(() => {
      if (selectedOption) {
          setfilter(extractTextFromJSX(selectedOption?.label) ?? "");
      }

      if (selectedOption?.icon) {
          setSelectedIcon(selectedOption?.icon);
      }
     
     
  }, [selectedOption]);

useEffect(()=>{
  if (clearValue && !value) {
    setfilter("");
}
},[value])
 
    return (
      <div className="w-full">
        {Boolean(label) && (
          <p className="text-[#000000] items-center flex text-[14px]  font-onestMedium">
            {label}
          </p>
        )}
        <div className="relative">
          <div
            onClick={() => setOpen(!open)}
            className={`flex justify-between cursor-pointer h-[45px] border-2
              border-textColorGreen  focus:border-2 rounded-[8px] focus:border-textColorGreen space-x-1 items-center
            ${
              selectedIcon && filter && "pl-4 max-475:pl-3"
            } pr-2 475:pr-4  w-full h-[45px] ${
              disabled ? "!bg-slate-50" : ""
            } py-3 text-sm font-rocGrotesk font-medium ${className}`}
          >
            {selectedIcon && filter && (
              <span className={`mb-[-8px]`}>{selectedIcon}</span>
            )}
            <div className="w-full mb-[-8px]">
              <input
                className={`inputText w-full  h-full max-475:pl-3 ${inputClassName} ${
                  selectedIcon ? "pl-2" : "pl-4"
                } bg-transparent  rounded !border-none focus:ring-0 -mt-3 font-rocGroteskRegular outline-none text-gm-50 focus:border-0 placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                placeholder={placeholder}
                type={"text"}
                value={filter}
                id={id}
                autoComplete={"off"|| "new-password"}
                ref={inputRef}
                name={`random_${Math.random()}`}
                disabled={disabled}
                onChange={handleFilterChange}
                onInput={handleInputChange}
                required={isRequired}
              />
            </div>
            {searchLoading ? (
              <LoaderSpin color={""} />
            ) : (
              <img
                src={"/images/arrow-down-s-line.svg"}
                alt="drop-down"
                className="mt-0.5 pr-2 cursor-pointer"
              />
            )}
          </div>
          {open &&
            !disabled &&
            (!searchLoading && filteredOptions && filteredOptions?.length > 0 ? (
              <div
                id="custom-select"
                ref={ref}
                className={`py-2 bg-white overflow-y-scroll absolute top-[101%] w-full h-auto max-h-64 z-[999] overflow-auto border ${dropdownClassName}`}
              >
                {customDropdownContainer && <div>{customDropdownContainer}</div>}
                {filteredOptions.map((option: any, idx) => {
                  const isOptionSelected =
                    selectedOption &&
                    selectedOption.value === option.value &&
                    selectedOption.label === option.label;
  
                  const optionLabel = option.label ?? "";
  
                  return (
                    <div
                      key={idx}
                      onClick={() =>
                        handleSelect(optionLabel, option.value, option.icon)
                      }
                      className={`flex items-center px-4 justify-between hover:textColorGreen ${
                        isOptionSelected &&
                        "textColorGreen " + activeOptionItemContainerClassName
                      } cursor-pointer ${optionItemContainerClassName}`}
                    >
                      <div
                        className={`text-sm flex gap-3 py-3 items-center font-rocGroteskRegular ${optionItemClassName}`}
                      >
                        {option.icon && <span>{option.icon}</span>}{" "}
                        <span>{optionLabel}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className={`py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-[999] overflow-auto border ${dropdownClassName}`}
                ref={ref}
              >
                <div className="flex px-4 justify-between hover:textColorGreen cursor-pointer">
                  <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskRegular">
                    <span>{searchLoading ? "Searching..." : "No Data"}</span>
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };
  
  export default SelectInput;
  