import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { DataProps, OrderTable } from "../components/Table";
import { useAcceptedVerifiedOrdersMutation } from "../services/slices/orderSlice";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { TableLoaderTwo } from "../components/Loader";
import { BarCode } from "../components/BarcodeGen";
import { DashboardModalAction } from "../components/Modal";
import { ComponentToPrintSVG } from "../components/ComponentToPrint";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../services/store";
import { setSelectedRows } from "../services/selectedRowAction";

interface OrderStatus {
  [key: number]: string;
}

interface Props {
  token: string | null;
  searchData: any;
  setSearchData: any;
  setCurrentPage: any;
  currentPage: any;
  onClickBack: any;
  onLogOut: any;
}

const PrintPage = ({
  token,
  searchData,
  setSearchData,
  setCurrentPage,
  currentPage,
  onClickBack,
  onLogOut,
}: Props) => {
  const [barcodeData, setBarcodeData] = useState<any>([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OrderStatus>({});
  const [selectAll, setSelectAll] = useState(false);
  const componentRef = useRef<any>();
  const componentMultipleRef = useRef<any>();
  const selectedRows: DataProps[] = useSelector(
    (state: RootState) => state.selectedRows
  );
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const searchText = urlParams.get("searchText");
  const endDate = urlParams.get("endDate");
  const startDate = urlParams.get("startDate");
  const page = urlParams.get("page");
  const client = urlParams.get("client");
  const status = urlParams.get("orderStatus");
  const navigate = useNavigate();
  const [
    searchOrder,
    {
      data: verifiedData,
      isLoading: isOrderLoading,
      isSuccess: isOrderSuccess,
    },
  ] = useAcceptedVerifiedOrdersMutation();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleMultiplePrint = useReactToPrint({
    content: () => componentMultipleRef.current,
  });

  const searchPaginatedOrder = async () => {
    try {
      await searchOrder({
        search: searchText,
        page: page,
        startCreatedDate: startDate,
        endCreatedDate: endDate,
        clientId: client,
        orderStatus: status,
      }).unwrap();
    } catch (e) {
      console.log({});
    }
  };

  useEffect(() => {
    if (token === null || token === "") {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    return () => {
      setIsPrinting(false);
    };
  }, []);

  const onPrint = async () => {
    try {
      await setOpenPrint(false);
      setIsPrinting(true);
      handlePrint();
      setIsPrinting(false);
    } catch (error) {
      console.log({});
      setIsPrinting(false);
    }
  };

  const clickPrint = (e: any) => {
    setBarcodeData(e);
    setOpenPrint(true);
  };

  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;

    setCurrentPage(selectedPage);

    if ("URLSearchParams" in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("page", selectedPage.toString());
      window.location.search = searchParams.toString();
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageParam = searchParams.get("page");

    if (pageParam) {
      const parsedPage = parseInt(pageParam);

      if (!isNaN(parsedPage)) {
        setCurrentPage(parsedPage);
      }
    }
  }, [window.location.search]);

  const updateSelectedRows = (newSelectedRows: DataProps[]) => {
    dispatch(setSelectedRows(newSelectedRows));
  };

  useEffect(() => {
    updateSelectedRows(selectedRows);

    searchPaginatedOrder();

    updateSelectedRows(selectedRows);
  }, []);

  useEffect(() => {
    if (isOrderSuccess) {
      setSearchData(verifiedData);
    }
  }, [verifiedData?.data, isOrderSuccess]);

  const handleRowSelect = (row: DataProps) => {
    if (selectAll) {
      dispatch(setSelectedRows([]));
      setSelectAll(false);
    } else {
      const updatedSelectedRows: DataProps[] = selectedRows.includes(row)
        ? selectedRows.filter((r) => r.id !== row.id)
        : [...selectedRows, row];

      dispatch(setSelectedRows(updatedSelectedRows));
      const dataToStore = JSON.stringify(updatedSelectedRows);
      localStorage.setItem("selectedItem", dataToStore);
    }
  };

  const handleSelectRows = (selectedData: DataProps[]) => {
    dispatch(setSelectedRows(selectedData));
    const storedSelectedRows = JSON.parse(
      localStorage.getItem("selectedItem") || "[]"
    );
    const data = [...selectedData, ...storedSelectedRows];
    const ids = data.map(({ id }) => id);
    localStorage.setItem(
      "selectedItem",
      JSON.stringify(
        data.filter(({ id }, index) => !ids.includes(id, index + 1))
      )
    );
  };

  console.log("barcodeData", barcodeData);

  const store = JSON.parse(localStorage.getItem("selectedItem") || "[]");

  return (
    <div className=" h-[100vh] mb-10  bg-white py-5 px-7 md:px-10">
      <div className="flex items-center justify-between">
        <div>
          <div>
            <img
              src={"/images/fez_logo.png"}
              className="w-[100px] md:w-[150px] md:h-[100px] h-[70px]"
              alt="fez_logo"
            />
          </div>
          <div className="pb-4 ml-5 flex space-x-2 items-center">
            <div className="cursor-pointer" onClick={onClickBack}>
              <img src="/images/arrow-back.svg" className="w-7 h-7 " alt="" />
            </div>
            <h1 className="text-[17px] font-normal ">
              BarCode Verification |
              <span className="text-textColorGreen"> Verified Order Page</span>
            </h1>
          </div>
        </div>
        <div className="flex items space-x-3 pr-10">
          <div onClick={()=>navigate("/scan-qr-code")}>
            <p className="text-textColorGreen underline cursor-pointer">Scan Page</p>
          </div>
          <div className="" onClick={onLogOut}>
            <button>Logout</button>
          </div>
        </div>
      </div>
      {!isOrderLoading ? (
        <>
          {store && store.length > 0 && (
            <button
              className={`bg-btnColor font-semibold mt-5 py-2.5 text-white cursor-pointer px-3 text-center rounded-[5px]`}
              onClick={() => {
                handleMultiplePrint();
                localStorage.removeItem("selectedItem");
              }}
            >
              {`Print All (${store.length}) `}
            </button>
          )}
          {searchData && searchData?.data?.data?.length > 0 ? (
            <div className="w-full  border-blackAlpha-880 rounded-xl shadow-md ">
              <OrderTable
                orderStatus={orderStatus}
                data={searchData?.data?.data}
                onPrint={(e: any) => {
                  clickPrint(e);
                }}
                printPage
                isPrinting={isPrinting}
                loading={isOrderLoading}
                printCheck
                onSelectRows={handleSelectRows}
                handleRowSelect={handleRowSelect}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
              />
              <div className="px-8 py-5 my-5 w-full flex-col justify-center  md:flex-row flex md:justify-between">
                <h1 className=" text-[#333333] flex md:text-start text-center mb-3 items-center text-[13px] ">
                  {`Showing ${searchData?.data?.meta?.from} - ${searchData?.data?.meta?.to} of ${searchData?.data?.meta?.total} results`}
                </h1>
                <div className="flex md:justify-normal  justify-center space-x-3">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={searchData?.data?.meta?.last_page}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"paginationLink"}
                    nextLinkClassName={"paginationLink"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={2}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-[16px] text-textColorBlack text-center my-32">
                No data!!!
              </p>
            </div>
          )}{" "}
        </>
      ) : (
        <TableLoaderTwo />
      )}

      <div className="hidden">
        <ComponentToPrintSVG details={store} ref={componentMultipleRef} />
      </div>

      <div className="hidden">
        <BarCode
          ref={componentRef}
          barcodeData={barcodeData}
        />
      </div>
      {openPrint && (
        <DashboardModalAction
          contentTitle="Print order"
          btnContent="Print"
          btnContentTwo="Cancel"
          imageStr="/images/print.png"
          verify
          content="Do you want to print the order?"
          onCloseTwo={() => {
            setOpenPrint(false);
          }}
          onClose={() => {
            setOpenPrint(false);
            onPrint();
          }}
        />
      )}
    </div>
  );
};

export default PrintPage;
