import { LoaderSpin } from "./Loader";

interface ModalActionProps {
  onClose?: any;
  onCloseTwo?: any;
  verify?: any;
  onClick?: any;
  imageStr?: string | any;
  contentTitle?: string;
  content?: string;
  btnContent?: string;
  btnContentTwo?: string;
  reasons?: boolean;
  isLoading?: boolean;
  success?: boolean;
  data?: any;
  onSelectChange?: any;
}

export const DashboardModalAction = ({
  onClose,
  imageStr,
  verify,
  onCloseTwo,
  success,
  contentTitle,
  content,
  btnContent,
  btnContentTwo,
  data,
  reasons,
  onSelectChange,
  isLoading,
}: ModalActionProps) => {
  if (!verify) {
    return null;
  }
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-blackAlpha   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative p-4 w-full flex items-center  max-w-[450px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-white py-3 min-w-[200px]  rounded-[20px]  shadow">
          <div className="py-6 flex justify-center  flex-col space-y-4 items-center px-6 lg:px-8">
            <img src={imageStr} alt="" width={64} height={64} />
            <h3 className="mb-4 text-xl text-[29px] font-redex font-[600] text-textBlack">
              {contentTitle}
            </h3>
            <p className="min-w-[200px] text-center text-[16px] text-textBlack font-productSans font-normal">
              {content}
            </p>
            {reasons && (
              <div className="pb-4 w-full flex justify-center">
                <select
                  name=""
                  onChange={onSelectChange}
                  placeholder="Select fleet size"
                  className="py-2  w-[90%] text-[0.8rem] font-medium border mt-2 outline-none border-textColorGreen rounded-[12px] focus:border-textColorGreen  focus:ring-0 focus:outline-none font-inter"
                >
                  <option key="" value="">
                    select a reason
                  </option>
                  {data?.map((d: any) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="w-full flex  space-x-4 mt-2">
              {btnContent && (
                <button
                  className={`bg-btnColor py-2.5 text-white cursor-pointer px-3 text-center rounded-full ${
                    success ? "w-[100%]" : "w-[70%]"
                  }  `}
                  onClick={onClose}
                  disabled={isLoading}
                >
                  {isLoading ? <LoaderSpin color="#ffffff" /> : btnContent}
                </button>
              )}
              {btnContentTwo && (
                <button
                  className="bg-white py-2.5 text-[#5B5B5B] border border-[#5B5B5B] cursor-pointer px-3 text-center rounded-full w-[70%] "
                  onClick={onCloseTwo}
                  disabled={isLoading}
                >
                  {btnContentTwo}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
