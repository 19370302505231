import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Client {
  id: number;
  clientId: string;
  fullName: string;
}

export interface ListResponse {
  data: {
    meta: {
      page: number;
      per_page: number;
      last_page: number;
      current_page: number;
      total: number;
      total_pages: number;
    };
    data: Client[];
  };
}

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listClients: builder.query<
      ListResponse,
      { perPage: number; pageNumber: number }
    >({
      query: ({
        perPage,
        pageNumber,
      }: {
        perPage: number;
        pageNumber: number;
      }) => {
        return {
          url: `clients?paginate=true&size=${perPage}&page=${pageNumber}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useListClientsQuery } = customerApi;
