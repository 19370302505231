import {Rings} from "react-loader-spinner";

interface Props{
    color: string
}
export const LoaderSpin =({color}:Props)=>{

    return (
      <div className="flex justify-center w-full items-center ">
        <Rings color={color} height={30} width={30} />
      </div>
    );
}

export const TableLoader =()=>{

return (
  <div className="animate-pulse w-full ">
    <div className="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
    <div className="h-4 bg-gray-300 mb-6 rounded"></div>
    <div className="h-4 bg-gray-200 mb-6 rounded"></div>
    <div className="h-4 bg-gray-300 mb-6 rounded"></div>
    <div className="h-4 bg-gray-200 mb-6 rounded"></div>
  </div>
);
}
export const TableLoaderTwo = () => {
  return (
    <div className="animate-pulse w-full ">
      <div className="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
      <div className="h-4 bg-gray-300 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-300 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
      <div className="h-4 bg-gray-200 mb-6 rounded"></div>
    </div>
  );
};