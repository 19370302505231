import { createReducer } from '@reduxjs/toolkit';
import { setSelectedRows } from './selectedRowAction';
import { DataProps } from '../components/Table';

const initialState: DataProps[] = [];

export const selectedRowsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSelectedRows, (state, action) => {
    // Merge the current state with the selected rows from the action payload
    return [ ...action.payload];
  });
});
